.rct9k-timeline-div {
  width: 100%;
  height: 95vh;
  background-color: #a5a5a5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Timeline items */
.rct9k-items-outer {
  font-size: 0.75em;
  white-space: nowrap;
  position: absolute;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
}
.rct9k-items-inner {
  overflow: auto;
  color: #ffffff;
  text-overflow: ellipsis;
  text-align: center;
  display: inline-block;
  margin: 1px;
  padding: 1px;
  width: 100%;
}
.rct9k-marker-overlay {
  z-index: 2;
  height: 475px;
  position: absolute;
  width: 2px;
  background: red;
  pointer-events: none;
}
.rct9k-item-renderer-inner {
  pointer-events: none;
}
.rct9k-row {
  /* white-space: nowrap; */
  box-sizing: border-box;
  border: 1px solid black;
  border-top: 0;
}
/* .rct9k-row:nth-child(2) {
  border-top: 1px solid black;
} */

.rct9k-items-selected {
  background-color: magenta;
}

.ReactVirtualized__Grid {
  overflow-x: hidden;
}

/* Timeline groups */
.rct9k-group {
  /*text-align: center;*/
  padding-left: 10px;
  font-size: 11px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  border-top: 0;
  background-color: white;
  z-index: 6;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
}
.rct9k-group-terminate {
  /*text-align: center;*/
  padding-left: 10px;
  font-size: 11px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  border-top: 0;
  background-color: white;
  z-index: 6;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
  color: white;
  background-color: #f44336;
}
/* 
.rct9k-group:first-child {
  border-top: 1px solid black;
} */

/* Timeline top timebar */
.rct9k-timebar-outer {
  height: 40px;
  background-color: white;
  border: 1px solid black;
  box-sizing: border-box;
}
.rct9k-timebar-inner {
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.rct9k-timebar-item {
  text-align: center;
  display: inline-block;
  border-left: 1px solid;
  white-space: nowrap;
  box-sizing: border-box;
}
.rct9k-timebar-item-selected {
  background-color: lightblue;
}

.rct9k-timebar-group-title {
  display: inline-block;
  position: absolute;
  height: 40px;
}

/* Multi-select box */
.rct9k-selector-outer {
  display: inline-block;
  background-color: #5bb3ff80;
  width: 1px;
  height: 1px;
  position: fixed;
  z-index: 5;
}

/* Row layer */
.rct9k-row-layer {
  border-right: 1px solid;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}

/*
.ra-input div:first-child {
  width: 100%;
}
*/
.ra-input-department {
  width: 30%;
  display: inline-block;
  margin-right: 5%; 
}

.ra-input-place {
  width: 30%;
  display: inline-block;
  margin-right: 5%; 
}

.ra-input-type {
  width: 30%;
  display: inline-block;
}

.ra-input-date {
  width: 45%;
  /*display: inline-block;*/
  margin-right: 3%; 
}

div[role=toolbar] {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%
}

#schedules {
  width: 100%;
}

#schedules p{
  display: none;  
}

#schedules .ra-input {
  width: 25%;
  display: inline-block;
  margin-right: 25px;
}

#schedules .ra-input div:first-child {
  width: 100%;
}

.ra-input-change{
  width: 30%;
  display: inline-block;
}

.ra-input-description{
  width: 65%;
  display: inline-block;
  margin-left: 25px;
}