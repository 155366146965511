.rhap_time {
    color: #172b61;
}

.rhap_progress-bar .rhap_progress-indicator {
    background: #172b61;
}

.rhap_progress-bar .rhap_progress-filled-indicator {
    background: #172b61;
}

.rhap_progress-bar .rhap_progress-filled {
    background: #172b61;
}

.rhap_progress-bar .rhap_progress-bar-show-download{
    background: #172b618c;
}

.rhap_progress-bar .rhap_download-progress {
    background: #172b618c;
}

.rhap_button-clear .rhap_volume-button {
    color: #172b61;
}
/* 
.progressbar .rhap_volume-bar {
    color: #172b618c;
} */

.progressbar .rhap_volume-bar .rhap_volume-indicator {
    background: #172b61;
}

.rhap_main-controls .rhap_button-clear {
    color: #172b61;
}

.rhap_main-controls .rhap_play-pause-button[aria-label="Play"]{
    color: #172b61;
}
/* 
.rhap_main-controls .rhap_play-pause-button[aria-label="Pause"]{
    color: #172b618c;
} */

.rhap_container {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 3px 0px rgba(0,0,0,0.12);
    border-radius: 0px 0px 4px 4px;
}

.rhap_volume-container .rhap_volume-button[aria-label="Mute"] {
    color: #172b61;
}
.rhap_volume-container .rhap_volume-button[aria-label="Unmute"] {
    color: red;
}

.rhap_volume-bar {
    background: #172b618c;
}

.rhap_volume-bar .rhap_volume-indicator {
    background: #172b61;
}